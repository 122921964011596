<template>
  <div class="login">
    <div class="enter_name">
      <div class="icon icon-gongdan"></div>
      <div class="enter">易工厂</div>
    </div>
    <div class="login_content">
      <div class="login_content_enter">登录易工厂</div>
      <div class="login_top">
        <van-tabs>
          <!-- <van-tab title="手机登录">
                        <van-form @submit="onSubmit">
                            <van-field
                                v-model="state.username"
                                placeholder="手机号码"
                            />
                            <van-field
                                v-model="state.password"
                                type="password"
                                placeholder="密码"
                            />
                            <div style="margin: 16px">
                                <van-button
                                    round
                                    block
                                    type="primary"
                                    native-type="submit"
                                >
                                    登录
                                </van-button>
                            </div>
                        </van-form>
                    </van-tab> -->
          <van-tab>
            <van-form @submit="onSubmit">
              <van-field v-model="factoryLogin.username" placeholder="账号"/>
              <van-field
                  v-model="factoryLogin.password"
                  type="password"
                  placeholder="密码"
              />
              <van-field v-model="factoryLogin.code" placeholder="请输入验证码">
                <template #button>
                  <img :src="baseImg" @click="changeImg"/>
                </template>
              </van-field>
              <div style="margin: 30px 16px 16px">
                <van-button round block type="primary" native-type="submit">
                  登录
                </van-button>
              </div>
            </van-form>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="bottom_div">
      <p>浙江吉时工业标识解析技术有限公司</p>
      <p>提供技术支持</p>
    </div>
  </div>
</template>
<script>
import {getImageCode} from "@/api/login";
import {mapActions} from "vuex";
import {ChangePageTitle} from '../../common/js/upTitle'

export default {
  data() {
    return {
      active: 0,
      factoryLogin: {
        username: "",
        password: "",
        code: "",
        verifyToken: "",
      },
      baseImg: "",
    };
  },
  created() {
    this.getBaseImg();
    ChangePageTitle('登录');
  },
  methods: {
    ...mapActions(["user/login"]),
    async onSubmit() {
      try {
        await this["user/login"](this.factoryLogin);
        this.$toast.success("登录成功");
        this.$router.push("/");
      } catch (error) {
        this.getBaseImg()
        console.log(error);
      }
    },
    async getBaseImg() {
      const res = await getImageCode();
      this.baseImg = res.image;

      this.factoryLogin.verifyToken = res.verifyToken;
    },
    changeImg() {
      this.getBaseImg();
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("../../common/img/login_bgc.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;

  .enter_name {
    height: 8vh;
    display: flex;
    margin: 4vh 0 0 4vw;
    line-height: 1;
    align-items: center;

    .icon {
      font-size: 40px !important;
      color: #6dcf85;
    }

    .enter {
      margin-left: 10px;
      font-size: 32px;
      color: #fff;
    }
  }

  .login_content {
    height: 54vh;
    width: 80%;
    margin: auto;
    margin-top: 10vh;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    padding: 10px;

    .login_content_enter {
      color: black;
      text-align: center;
      line-height: 1;
      margin-top: 6vh;
      font-size: 40px;
      font-weight: 700;
    }

    .login_top {
      margin-top: 25px;

      .van-tabs__content {
        .van-tab__pane {
          margin-top: 5px;
          font-size: 18px;
          color: black;

          .van-field {
            border-bottom: 1px solid #999;
          }
        }
      }
    }

    .departmen_content {
      width: 80%;
      background-color: #fff;
      opacity: 1;
    }
  }

  .bottom_div {
    margin-top: 26%;
    height: 30vh;
    text-align: center;
    font-size: 24px;
    color: black;
  }
}

/deep/ .van-tabs__nav {
  background-color: transparent;
}

/deep/ .van-cell {
  background-color: transparent;
}

/deep/ .van-field__button {
  width: 140px;

  img {
    width: 100%;
    height: 100%;
  }

}
</style>
